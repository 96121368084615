export default {
  props: {
    multiSelectedData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      approveLoading: false,
      declineLoading: false,
      holdLoading: false
    };
  },
  computed: {
    selectedIDs() {
      return this.multiSelectedData.map(item => item.id);
    }
  },
  methods: {
    async approveBulk() {
      this.approveLoading = true;
      const formData = new FormData();
      formData.append("ids[]", this.selectedIDs);
      formData.append("status", 1);
      this.$store.dispatch("senderid/bulkActionSenderID", formData).then(() => {
        this.$message.success({
          message: "Sender IDs approved successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("ids[]")
        });
      }).catch(error => {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      }).finally(() => {
        this.approveLoading = false;
      });
    },
    async declineBulk() {
      this.declineLoading = true;
      const formData = new FormData();
      formData.append("ids[]", this.selectedIDs);
      formData.append("status", 2);
      this.$store.dispatch("senderid/bulkActionSenderID", formData).then(() => {
        this.$message.success({
          message: "Sender IDs toggled successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("ids[]")
        });
      }).catch(error => {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      }).finally(() => {
        this.declineLoading = false;
      });
    },
    async holdBulk() {
      this.holdLoading = true;
      const formData = new FormData();
      formData.append("ids[]", this.selectedIDs);
      formData.append("status", 3);
      this.$store.dispatch("senderid/bulkActionSenderID", formData).then(() => {
        this.$message.success({
          message: "Sender IDs toggled successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("ids[]")
        });
      }).catch(error => {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      }).finally(() => {
        this.holdLoading = false;
      });
    }
  }
};