var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('SenderIDTable', {
    attrs: {
      "parent": _vm.parent
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };